import React, { useState, createRef } from "react";
import axios from "axios";
import config from "../config";
import Cookie from "js-cookie";
import { Spin, Modal, Button, Space, Alert, notification } from "antd";
import PinInput from "react-pin-input";
import "../assets/auth-styles.css";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import ReCaptchaV2 from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import {
  checkPasswordStrength,
  strongPasswordText,
} from "../helperFunctions/functions";
export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [loader, setLoad] = useState(false);
  const [loadLogin, setLoadLogin] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [eye, seteye] = useState(false);
  const [sucessMsg, setSuccessMsg] = useState("");
  const [error, setError] = useState(false);
  const [form, setForm] = useState("");
  const [success, setSuccess] = useState(false);
  const [otpBox, setOtpBox] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [otpAlert, setOtpAlert] = useState(false);

  const [eyeNewPassword, seteyeNewPass] = useState(false);
  const [eyeConfirm, seteyeConfirm] = useState(false);

  const [serverError, setServerError] = useState(false);
  const [serverErrorOtp, setServerErrorOtp] = useState(false);
  const [serverErrorPass, setServerErrorPass] = useState(false);

  const [forgotPass, setForgotPass] = useState(false);
  const [resetPass, setResetPass] = useState(false);
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [newPass, setNewPass] = useState(false);
  const [confirmNewPass, setConfirmNewPass] = useState(false);
  const [msg, setMsg] = useState("");
  const [msgPrompt, setMsgPrompt] = useState(false);
  const recaptchaRef = createRef();
  const [loginFields, setLoginFields] = useState(false);
  const [fillCaptha, setFillCaptha] = useState(false);
  const [strongPasswordError, setStrongPasswordError] = useState(false);

  const handleChange = (otp) => setOtpValue(otp);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const handleForgotPass = () => setForgotPass(true);
  const cancelForgotPass = () => {
    setEmailOrPhone("");
    setResetPass(false);
    setForgotPass(false);
    setNewPass("");
    setConfirmNewPass("");
    setOtpValue("");
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: "Login",
      description: msg,
    });
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const login = () => {
    const data = {
      email: email,
      password,
    };
    if (data.email && data.password) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/auth/login`, data)
        .then((res) => {
          setOtpValue("");
          setLoad(false);
          if (res.data.status) {
            Cookie.set("userCheckList", res.data.result.checkList);
            if (res.data.result.authenticated) {
              openNotificationWithIcon("success", res.data.message);
              setTimeout(() => {
                sessionStorage.setItem("token", res.data.result.accessToken);
                if (res.data.result.employer) {
                  sessionStorage.setItem(
                    "employer_id",
                    res.data.result.employer.id
                  );
                }
                if (res.data.result.employee) {
                  sessionStorage.setItem(
                    "employeeID",
                    res.data.result.employee.id
                  );
                  sessionStorage.setItem(
                    "employer_id",
                    res.data.result.employee.employerId
                  );
                }
                if (res.data.result.user) {
                  sessionStorage.setItem("userId", res.data.result.user.id);
                }
                Cookie.set("salarioPayUserInfo", res.data.result.user);
                Cookie.set("salarioPayUserRole", res.data.result.user.roles);
                if (res.data.result.user?.roles?.length > 1) {
                  window.location.href = "/choose-profile";
                } else {
                  sessionStorage?.setItem(
                    "activeRole",
                    res.data.result.user.roles[0].name
                  );
                  if (sessionStorage?.getItem("activeWindowState")) {
                    if (
                      sessionStorage?.getItem("logOutRole") ===
                      res.data.result.user.roles[0].name
                    ) {
                      window.location.href = `/${sessionStorage?.getItem(
                        "activeWindowState"
                      )}`;
                    } else {
                      window.location.href = "/dashboard";
                    }
                  } else {
                    window.location.href = "/dashboard";
                  }
                }
              }, 1000);
            } else {
              setOtpBox(true);
              setError(true);
              setErrorMsg(res.data.message);
              sessionStorage.setItem("tempToken", res.data.result.accessToken);
              Notification("success", "Success", res.data?.message);
            }

            // props.history.push("/login");
          } else {
            Notification("error", "Error", res.data?.message);
            setError(true);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 3000);
          }
        })
        .catch((err) => {
          if (err) {
            Notification("error", "Error", err?.response?.data?.message);
            // setPass("");
            // setEmail("");
            setOtpValue("");
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 3000);
          }
        });
    } else {
      setLoginFields(true);
      setTimeout(() => {
        setLoginFields(false);
      }, 3000);
    }
  };

  const confirmCaptha = (e) => {
    e.preventDefault();
    // if (!form.token) {
    //   setFillCaptha(true);
    //   setTimeout(() => {
    //     setFillCaptha(false);
    //   }, 2500);
    //   return;
    // }
    login();
  };

  const LoginWithOtp = (e) => {
    e.preventDefault();
    const data = {
      token: otpValue,
    };
    if (data.token) {
      setLoadLogin(true);
      axios
        .post(`${config.baseUrl}/v1/auth/verify-login`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("tempToken")}`,
          },
        })
        .then((res) => {
          setLoadLogin(false);
          setOtpValue("");
          if (res.data.status) {
            openNotificationWithIcon("success", res.data.message);
            setTimeout(() => {
              sessionStorage.setItem("token", res.data.result.accessToken);
              if (res.data.result.employer !== null) {
                sessionStorage.setItem(
                  "employer_id",
                  res.data.result.employer.id
                );
              }
              if (res.data.result.employee !== null) {
                sessionStorage.setItem(
                  "employeeID",
                  res.data.result.employee.id
                );
                sessionStorage.setItem(
                  "employer_id",
                  res.data.result.employee.employerId
                );
              }
              if (res.data.result.user !== null) {
                sessionStorage.setItem("userId", res.data.result.user.id);
              }
              Cookie.set("salarioPayUserInfo", res.data.result.user);
              Cookie.set("salarioPayUserRole", res.data.result.user.roles);
              if (res.data.result.user?.roles?.length > 1) {
                window.location.href = "/choose-profile";
              } else {
                sessionStorage.setItem(
                  "activeRole",
                  res.data.result.user.roles[0].name
                );
                window.location.href = "/dashboard";
              }
            }, 1000);
          } else {
            Notification("error", "Error", res?.data?.message);
          }
        })
        .catch((err) => {
          setLoadLogin(false);
          if (err) {
            Notification("error", "Error", "Something went wrong, Try Again!");
          }
        });
    } else {
      Notification("error", "Error", "OTP is required!");
    }
  };

  const forgotPassFunc = (e) => {
    e.preventDefault();
    const data = {
      mobileOrEmail: emailOrPhone,
    };
    if (data.mobileOrEmail) {
      setLoadLogin(true);
      axios
        .post(`${config.baseUrl}/v1/auth/forgot-password`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("tempToken")}`,
          },
        })
        .then((res) => {
          setLoadLogin(false);
          if (res.data.status) {
            setTimeout(() => {
              setResetPass(true);
            }, 1700);
            setSuccess(true);
            setSuccessMsg(res.data.message);
            setTimeout(() => {
              setSuccess(false);
            }, 1500);
          } else {
            setMsgPrompt(true);
            setMsg(res.data.message);
            setTimeout(() => {
              setMsgPrompt(false);
            }, 1500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoadLogin(false);
            setServerErrorPass(true);
            setTimeout(() => {
              setServerErrorPass(false);
            }, 2500);
          }
        });
    } else {
      setOtpAlert(true);
      setTimeout(() => {
        setOtpAlert(false);
      }, 2500);
    }
  };

  const passwordStrength = checkPasswordStrength(newPass);

  const resetPassFunc = (e) => {
    const data = {
      confirmPassword: confirmNewPass,
      newPassword: newPass,
      identifier: emailOrPhone,
      token: otpValue,
    };
    if (data.token) {
      setLoadLogin(true);
      axios
        .post(`${config.baseUrl}/v1/auth/reset-password`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("tempToken")}`,
          },
        })
        .then((res) => {
          setLoadLogin(false);
          if (res.data.status) {
            setForgotPass(false)
            Notification("success", "Success", res.data.message);
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          if (err) {
            setLoadLogin(false);
            setServerErrorPass(true);
            setTimeout(() => {
              setServerErrorPass(false);
            }, 1500);
          }
        });
    } else {
      Notification("error", "Error", "OTP token is required");
    }
  };

  const handleToken = (token) => {
    setForm((currentForm) => {
      return { ...currentForm, token };
    });
  };
  const handleExpire = () => {
    setForm((currentForm) => {
      return { ...currentForm, token: null };
    });
    window.location.reload();
  };

  if (sessionStorage.getItem("token") && Cookie.getJSON("salarioPayUserInfo")) {
    window.location.href = "/dashboard";
  }
  return (
    <>
      <div className="login-page">
        <div className="loader">
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-12 col-lg-4">
              <div className="card login-box-container">
                <div className="card-body">
                  <div className="authent-logo">
                    <img
                      src="../../assets/images/loggo.png"
                      alt=""
                      width="40%"
                    />
                  </div>
                  <div className="authent-text" style={{ color: "#222" }}>
                    <p>Welcome to SalarioPay!</p>
                    <p>Please Sign-in to your account.</p>
                  </div>

                  <form>
                    {loginFields && (
                      <div className="pb-4">
                        <Alert
                          message="Email and Password are required!"
                          type="error"
                          showIcon
                        />
                      </div>
                    )}

                    {fillCaptha && (
                      <p className="text-center text-danger font-weight-bold">
                        Captha is required
                      </p>
                    )}

                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="floatingInput"
                          placeholder="name@example.com"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                        <label for="floatingInput">Email address</label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div
                        className="mt-2  d-block w-100 d-flex align-items-center justify-content-between border"
                        style={{ borderRadius: "10px" }}
                      >
                        <div
                          className=""
                          style={{
                            width: "90%",
                          }}
                        >
                          <div className="form-floating">
                            <input
                              type={!eye ? "password" : "text"}
                              className="form-control"
                              id="floatingPassword"
                              placeholder="Password"
                              onChange={(e) => setPass(e.target.value)}
                              value={password}
                              style={{ border: 0 }}
                            />
                            <label for="floatingPassword">Password</label>
                          </div>
                        </div>
                        <div
                          className="d-flex align-items-center justify-content-center h-100 pointer"
                          onClick={() => seteye(!eye)}
                          style={{
                            width: "10%",
                          }}
                        >
                          {!eye ? <BsEye /> : <BsEyeSlash />}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 ">
                      <div className="d-flex justify-content align-items-center">
                        <span className="pr-3">
                          <i className="fa fa-lock"></i>
                        </span>
                        <span
                          className=""
                          for="exampleCheck1"
                          style={{ cursor: "pointer" }}
                          onClick={handleForgotPass}
                        >
                          Forgot Password?
                        </span>
                      </div>
                    </div>
                    <div className=" w-100 d-flex justify-content-start mt-3 align-items-center">
                      <ReCaptchaV2
                        ref={recaptchaRef}
                        sitekey="6LfI3WAnAAAAAPUGEskLIZq3h4ScLOiv1jlxz6q1"
                        onChange={handleToken}
                        onExpire={handleExpire}
                      />
                      <br />
                    </div>
                    <br />

                    <div class="  mt-1">
                      <button
                        type="submit"
                        className="btn btn-info w-100 m-b-xs"
                        onClick={confirmCaptha}
                        style={{ background: "#1FC157", color: "white" }}
                      >
                        {loader ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <ThreeDots
                              visible={loader}
                              height="20"
                              width="50"
                              color="#ffffff"
                              radius="9"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                            />
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                  <div className="authent-reg">
                    <p>
                      Not registered?{" "}
                      <Link to="/register">Create an account</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Input Otp Code"
        visible={otpBox}
        width={520}
        okText="Submit"
        onOk={LoginWithOtp}
        maskClosable={false}
        footer={false}
        // maskStyle={{background: '#f3f4f7'}}
      >
        <div className="container">
          {loadLogin && (
            <p className="text-center">
              <Spin />
            </p>
          )}

          <p>
            To Login kindly input the code sent to{" "}
            <span className=" font-weight-bold" style={{ color: "#1FC157" }}>
              {email}
            </span>{" "}
            to gain access to your account.
          </p>

          <div className="d-flex justify-content-center align-items-center">
            <PinInput
              length={5}
              initialValue={otpValue}
              secret
              onChange={(value, index) => setOtpValue(value)}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px" }}
              inputStyle={{ borderColor: "green" }}
              inputFocusStyle={{ borderColor: "blue" }}
              onComplete={(value, index) => {}}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          <hr />
          <div className="d-flex justify-content-end align-items-center">
            <button
              className="btn btn-info m-b-xs"
              style={{ background: "#1FC157", color: "white" }}
              onClick={LoginWithOtp}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        title={resetPass ? "Reset Password" : "Forgot Password"}
        visible={forgotPass}
        okText="Submit"
        onOk={LoginWithOtp}
        footer={false}
        onCancel={cancelForgotPass}
        maskClosable={false}
        // maskStyle={{background: '#f3f4f7'}}
      >
        <form>
          {loadLogin && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {otpAlert && (
            <div className="pb-3">
              <Alert
                message={
                  !resetPass
                    ? "Email or Phone number is required!"
                    : "All fields are required!"
                }
                type="error"
                showIcon
              />
            </div>
          )}
          {success && (
            <div className="pb-3">
              <Alert message={sucessMsg} type="success" showIcon />
            </div>
          )}
          {msgPrompt && (
            <div className="pb-3">
              <Alert message={msg} type="error" showIcon />
            </div>
          )}
          {serverErrorPass && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}
          {!resetPass ? (
            <div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setEmailOrPhone(e.target.value)}
                    value={emailOrPhone}
                  />
                  <label for="floatingInput">
                    Email address OR Phone Number
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div class="mb-3">
                <div
                  className="mt-2  d-block w-100 d-flex align-items-center justify-content-between border"
                  style={{ borderRadius: "10px" }}
                >
                  <div
                    className=""
                    style={{
                      width: "90%",
                    }}
                  >
                    <div className="form-floating">
                      <input
                        type={!eyeNewPassword ? "password" : "text"}
                        class="form-control"
                        style={{ border: 0 }}
                        {...register("passwordNew", {
                          required: "Password is required!",
                          onChange: (e) => setNewPass(e.target.value),
                          pattern: {
                            value:
                              /^^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}/i,
                            message:
                              "Invalid Password Format, must contain 1 Capital letters, number, and special character",
                          },
                        })}
                        maxLength="64"
                      />
                      <label for="floatingPassword">New Password</label>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center h-100 pointer"
                    onClick={() => seteyeNewPass(!eyeNewPassword)}
                    style={{
                      width: "10%",
                    }}
                  >
                    {!eyeNewPassword ? <BsEye /> : <BsEyeSlash />}
                  </div>
                </div>
                {errors.passwordNew && (
                  <span className="validate-error">
                    {errors.passwordNew.message}
                  </span>
                )}
              </div>

              <div className="mb-3">
                <div
                  className="mt-2  d-block w-100 d-flex align-items-center justify-content-between border"
                  style={{ borderRadius: "10px" }}
                >
                  <div
                    className=""
                    style={{
                      width: "90%",
                    }}
                  >
                    <div class="form-floating">
                      <input
                        type={!eyeConfirm ? "password" : "text"}
                        class="form-control"
                        maxLength="64"
                        style={{ border: 0 }}
                        {...register("cpassword", {
                          required: "Confirm Password is required!",
                          validate: (value) =>
                            value === newPass || "The passwords do not match",
                          onChange: (e) => setConfirmNewPass(e.target.value),
                        })}
                      />
                      <label for="floatingPassword">Confirm New Password</label>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center h-100 pointer"
                    onClick={() => seteyeConfirm(!eyeConfirm)}
                    style={{
                      width: "10%",
                    }}
                  >
                    {!eyeConfirm ? <BsEye /> : <BsEyeSlash />}
                  </div>
                </div>
                {errors.cpassword && (
                  <span className="validate-error">
                    {errors.cpassword.message}
                  </span>
                )}
              </div>
              <div className="mb-3 ">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    maxLength="5"
                    {...register("otp", {
                      required: "OTP is required!",
                      onChange: (e) => setOtpValue(e.target.value),
                    })}
                  />
                  {errors.otp && (
                    <span className="validate-error">{errors.otp.message}</span>
                  )}

                  <label for="floatingPassword">OTP</label>
                </div>
              </div>
            </div>
          )}

          <div className="d-grid">
            {!resetPass ? (
              <button
                type="submit"
                className="btn btn-info m-b-xs"
                onClick={forgotPassFunc}
                style={{ background: "#1FC157", color: "white" }}
              >
                Submit
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-info m-b-xs"
                onClick={handleSubmit(resetPassFunc)}
                style={{ background: "#1FC157", color: "white" }}
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </Modal>
    </>
  );
}
